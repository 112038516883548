:root {
  --blue-color: #2F5FA1;
}

.color-blue {
  color: var(--blue-color) !important;
}

.header {
  height: auto !important;
}

.background-color-blue-radial {
  background: radial-gradient(circle, rgba(47, 95, 161, 1) 35%, rgba(37, 78, 134, 1) 100%);
  /* background-color: #15133C !important; */
  color: white;
}

.background-color-lightgray-radial {
  background: radial-gradient(circle, rgb(230, 230, 230) 50%, rgb(209, 209, 209) 100%);
  /* background-color: #15133C !important; */
  color: white;
}

.background-color-CLBGroup {
  background: rgb(136, 82, 122);
  /* background-color: #15133C !important; */
  color: white;
}

/* Date input field left aligned -> IOS */
input[type="date"]::-webkit-date-and-time-value {
  text-align: left !important;
}

input[type="date"] {
  text-align: left !important;
}

.simple-header {
  height: 56px;
  background-color: var(--blue-color);
  color: white;
}

.checkbox-size {
  height: 25px !important;
  width: 25px !important;
}

html,
body {
  margin: 0;
  font-family: "Helvetica" !important;
  overflow-x: hidden !important;
  /* overflow-y: scroll !important; */
}

/* Styling sidebar */
#page-wrap {
  text-align: center;

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

.redPaper {
  padding: 20px;
  color: azure !important;
  ;
  background-color: rgb(224, 93, 93) !important;
}

.greenPaper {
  padding: 20px;
  color: azure !important;
  ;
  background-color: rgb(46, 125, 50) !important;
}

.greyPaper {
  padding: 20px;
  color: white !important;
  ;
  background-color: rgb(168, 168, 168) !important;
}

.makeTransparent {
  background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)) !important;
}

.fill-window {
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  /* overflow: "auto" */
}

.link {
  color: #000000;
}

.link:hover {
  color: rgb(119, 119, 119)
}